import * as Yup from 'yup';

import messages from '../../../constants/messages.constants';
import { rePhoneNumber } from '../../../constants/phoneValidation.constants';
import { StudentStatusEnum } from '../../../types/applicant.types';

const { required } = messages;
export const TransformApplicantFormSchema = Yup.object().shape({
  name: Yup.string().required(required),
  nameHy: Yup.string().nullable(true),
  nameEn: Yup.string().nullable(true),
  email: Yup.string().email('Invalid email').required(required),
  phoneNumber: Yup.string()
    .required(required)
    .matches(rePhoneNumber, 'Phone number is not valid'),
  status: Yup.mixed<StudentStatusEnum>()
    .oneOf(Object.values(StudentStatusEnum))
    .required(required),
  addTo: Yup.string(),
  groupId: Yup.number(),
  courseUuid: Yup.string(),
  scheduledDate: Yup.string().nullable(true),
  discount: Yup.number().min(0).max(100).nullable(true)
});


export const studentGroupTerminatedFormSchema = Yup.object().shape({
  status: Yup.mixed<StudentStatusEnum>()
    .oneOf(Object.values(StudentStatusEnum))
    .required(required),
});
