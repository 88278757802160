import { Row, Spin } from 'antd';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import { FormikValues } from 'formik/dist/types';
import React, { useMemo } from 'react';
import { useQuery } from 'react-query';

import CustomDateAndTimePicker from '../../../components/display/CustomTimePicker/CustomTimePicker';
import {
  Button,
  Flex,
  Input as FormInput,
  SingleSelectFormik,
} from '../../../components/general';
import {
  ApplicantCreateStatusOptions,
  ApplicantDiscountOptions,
  ApplicantPaymentMethodOptions,
} from '../../../constants/applicant.constants';
import { UseQueryTypes } from '../../../constants/useQuery.constants';
import { generateContext } from '../../../helpers/generateContext.helpers';
import { getCourses } from '../../../services/courses.service';
import { getGroupNames } from '../../../services/groups.service';
import {
  ICreateStudent,
  RegistrationSource,
} from '../../../types/applicant.types';
import { ITransform } from '../../../types/form.types';
import { TransformApplicantFormSchema } from '../schemas/transformApplicant.form.schema';
import { GroupField } from './GroupField';

type TransformApplicantType = {
  isEditForm?: boolean;
};

export const TransformApplicantForm = ({
                                         onSubmit,
                                         initialValues,
                                         submitText = 'Apply',
                                       }: ITransform<ICreateStudent> & TransformApplicantType) => {
  const groupsQuery = useQuery(UseQueryTypes.GROUP_NAMES, getGroupNames);
  const coursesQuery = useQuery(UseQueryTypes.COURSES, getCourses);

  const coursesContext = useMemo(() => {
    return generateContext(coursesQuery.data || [], (course) => ({
      label: course.name,
      value: course.id,
      uuId: course.uuid,
    }));
  }, [coursesQuery.data]);

  const handleSubmit = async (student: Partial<ICreateStudent>) => {
    await onSubmit({
      registrationSource: RegistrationSource.MANUAL,
      ...student,
    });
  };
  const isScreenLoading = groupsQuery.isLoading;

  const showDate = (props: FormikValues) => {
    return (
      props.values.status === ApplicantCreateStatusOptions.EXAM_SCHEDULED.value ||
      props.values.status === ApplicantCreateStatusOptions.INTERVIEW_SCHEDULED.value
    );
  };

  const { DROP_OUT_AFTER_ENROLLMENT, ...availableStatusOptions } = ApplicantCreateStatusOptions

  return (
    <Spin spinning={isScreenLoading}>
      <Formik
        onSubmit={handleSubmit}
        initialValues={initialValues}
        validationSchema={TransformApplicantFormSchema}
        enableReinitialize
      >
        {(props) => {
          return (
            <Form
              name="filters-1"
              style={{
                width: '100%',
                height: 'calc(100vh - 120px)',
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <Field name="name" label="Name" component={FormInput} required/>
              <ErrorMessage name="name" component="span" className="error"/>
              <Field name="nameHy" label="Name (hy)" component={FormInput}/>
              <ErrorMessage name="nameHy" component="span" className="error"/>

              <Field name="nameEn" label="Name (en)" component={FormInput}/>
              <ErrorMessage name="nameEn" component="span" className="error"/>

              <Field
                name="email"
                label="Email"
                component={FormInput}
                required
              />
              <ErrorMessage name="email" component="span" className="error"/>

              <Field
                name="phoneNumber"
                label="Phone"
                component={FormInput}
                required
              />
              <ErrorMessage
                name="phoneNumber"
                component="span"
                className="error"
              />

              <Field
                label="Status"
                name="status"
                component={SingleSelectFormik}
                options={availableStatusOptions}
                required
              />
              <ErrorMessage name="status" component="span" className="error"/>

              {showDate(props) && (
                <Row align="middle">
                  <CustomDateAndTimePicker
                    label="Enter schedule date"
                    defaultValue={props.values.scheduledDate}
                    getDate={(date) => {
                      props.setFieldValue('scheduledDate', date);
                    }}
                  />

                  <ErrorMessage
                    name="scheduledDate"
                    component="span"
                    className="error"
                  />
                </Row>
              )}
              <Field
                label="Course"
                name="courseId"
                component={SingleSelectFormik}
                options={coursesContext}
                required
              />
              <ErrorMessage
                name="courseId"
                component="span"
                className="error"
              />

              <GroupField name="groupId"/>
              {/*  <Field
                label='Group'
                name='groupId'
                component={SingleselectFormik}
                options={groupsContext}
              />
              <ErrorMessage name='groupId' component='span' className='error' />*/}

              <Field label="Payment method"  name="paymentMethod" component={SingleSelectFormik} options={ApplicantPaymentMethodOptions} required />
              <ErrorMessage name="paymentMethod" component="span" className="error" />

              <Field label="Payment discount"  name="discount" component={SingleSelectFormik} options={ApplicantDiscountOptions}/>
              <ErrorMessage name="discount" component="span" className="error" />

              <Flex
                style={{ marginTop: 'auto' }}
                justifyContent="space-between"
              >
                <Button
                  htmlType="button"
                  style={{ minWidth: 160, marginRight: 8 }}
                  onClick={() => {
                    return props.handleReset();
                  }}
                >
                  Reset
                </Button>
                <Button
                  style={{ minWidth: 160 }}
                  htmlType="submit"
                  variant="primary"
                  disabled={!(props.dirty && props.isValid)}
                >
                  {submitText}
                </Button>
              </Flex>
            </Form>
          );
        }}
      </Formik>
    </Spin>
  );
};
