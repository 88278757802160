import axios from 'axios';

import { stringifyObject } from '../helpers/stringifyObj.helper';
import { Sorters } from '../types/general.types';
import { IFilterLeadGen, ILeadGenFilterResponse } from '../types/leadGen.types';

export async function filterLeadGen(criterias: Partial<IFilterLeadGen>, query: Partial<Sorters>) {
  const queryString = stringifyObject(query);
  const r = await axios.post(`/leadGen/filter?${queryString}`, criterias);
  return r.data as Promise<ILeadGenFilterResponse>;
}
